import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Services/Hero";
import Strategy from "../components/Services/Strategy";
import Differentiation from "../components/Services/Differentiation";
import Services from "../components/Services/Services";
import "../style/sports.scss";

// eslint-disable-next-line
export const SportsPageTemplate = ({
  heropitch,
  strategypitch,
  servicespitch,
  pageContext,
}) => {
  return (
    <>
      <div>
        <section id="sports">
          <div className="sports-heropitch">
            <div>
              <h2 style={{ color: "rgba(83, 213, 245, 1)", marginTop: "0" }}>
                {heropitch?.title}
              </h2>
              <p>{heropitch?.description1}</p>
              <p>{heropitch?.description2}</p>
            </div>
            <div style={{ paddingTop: "10px" }}>
              <img src={heropitch?.heroImg?.publicURL} />
            </div>
          </div>
        </section>
        <div>
          <img src={heropitch?.img?.publicURL} style={{ width: "100%" }} />
        </div>
        {/* <Hero content={heropitch} language={pageContext.language || "en"} /> */}
        {/* <Strategy content={strategypitch} /> */}
        {/* <Differentiation content={differentiationpitch} /> */}
        <Services content={servicespitch} state={false} />
      </div>
    </>
  );
};

SportsPageTemplate.propTypes = {
  heropitch: PropTypes.object,
  strategypitch: PropTypes.object,
  servicespitch: PropTypes.object,
};

const SportsPage = ({ data, pageContext, path }) => {
  const { frontmatter } = data.markdownRemark;

  const frontmatterFromLanguage = frontmatter[pageContext.language || "en"];

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <SportsPageTemplate
        heropitch={frontmatterFromLanguage?.heropitch}
        strategypitch={frontmatterFromLanguage?.strategypitch}
        servicespitch={frontmatterFromLanguage?.servicespitch}
        pageContext={pageContext}
      />
    </Layout>
  );
};

SportsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SportsPage;

export const pageQuery = graphql`
  query SportsPageTemplate {
    markdownRemark(
      frontmatter: { en: { templateKey: { eq: "sports-page" } } }
    ) {
      frontmatter {
        en {
          heropitch {
            title
            description1
            description2
            heroImg {
              publicURL
            }
            img {
              publicURL
            }
          }
          servicespitch {
            title1
            title2
            services {
              title2
              description
              icon {
                publicURL
              }
            }
          }
        }
        it {
          heropitch {
            title
            description1
            description2
            heroImg {
              publicURL
            }
            img {
              publicURL
            }
          }
          servicespitch {
            title1
            title2
            services {
              title2
              description
              icon {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
