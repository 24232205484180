import * as React from "react";
import { Link } from "gatsby";
import "../../style/services.scss";

const sections = {
  en: [
    "Strategy",
    "Web",
    "SEO",
    "Content marketing",
    "And More…",
    "Social",
    "CRM",
    "Paid media",
  ],
  es: [
    "ESTRATEGIA",
    "MARCA DEL EJECUTIVO",
    "SEO",
    "MARKETING DE CONTENIDOS",
    "WEB",
    "SOCIAL",
    "CRM",
    "MEDIOS PAGADOS",
  ],
  it: [
    "Strategia",
    "WEB",
    "SEO",
    "Content marketing",
    "And More…",
    "Social",
    "CRM",
    "Paid media",
  ],
};

function Hero({ content, language }) {
  return (
    <section id="hero">
      <div className="section-content">
        <div>
          <div className="catchy">{content?.title1}</div>
          <h2>{content?.title2}</h2>
          <p>{content?.description}</p>
        </div>
        <div className="hero-grid-container">
          <div className="hero-grid">
            {sections[language].map((section) => (
              <div className="item">
                <div className="label">
                  <span>{section}</span>
                </div>
              </div>
            ))}
            <div className="star-container">
              <div className="star">
                <div className="branch"></div>
                <div className="branch"></div>
                <div className="branch"></div>
                <div className="branch"></div>
                <div className="branch"></div>
                <div className="branch"></div>
                <div className="branch"></div>
                <div className="branch"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
