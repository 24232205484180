import * as React from "react";
import { Link } from "gatsby";
import "../../style/services.scss";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";

function Strategy({ content }) {
  return (
    <section id="strategy">
      <div className="section-content">
        <div>
          <h2 style={{ textAlign: "center" }}>{content?.title1}</h2>
          <div className="catchy highlighted" style={{ textAlign: "center" }}>
            {content?.title2}
          </div>
          <p
            style={{
              width: "60rem",
              maxWidth: "100%",
              textAlign: "center",
              margin: "4rem auto",
            }}
          >
            {content?.description}
          </p>

        </div>
      </div>
    </section>
  );
}

export default Strategy;
