import * as React from "react";
import { Link } from "gatsby";
import "../../style/services.scss";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";

function Services({ content,alternate }) {
  return (
    <section id="services">
      <div className="section-content">
        <div>
          {
            alternate === true ? <>
          <h2 style={{ textAlign: "center" }}>{content?.title1}</h2>
          <div className="catchy highlighted" style={{ textAlign: "center" }}>
            {content?.title2}
          </div>
            </>: <>
          <div className="catchy highlighted" style={{ textAlign: "center" }}>
            {content?.title2}
            <h2 style={{ textAlign: "center" }}>{content?.title1}</h2>
          </div>
            </>
          }
          <div className="services-container">
            {content?.services?.map((service) => (
              <div key={`serice-${content?.title2}`}>
                <img src="/static/assets/services/services/service-bg.png" />
                <div style={{ display: "flex" }}>
                  {
                    alternate === true ?
                  <div
                    style={{
                      paddingTop: "50px",
                      margin: "-5px",
                      zIndex: 1,
                      backgroundColor: "rgba(17, 17, 17, 0.8)",
                      background: "rgba(17, 17, 17, 0.8)",
                      borderRadius: "100%",
                    }}
                  >
                    <div
                      className="icon"
                      style={{
                        backgroundImage: service?.icon?.publicURL
                          ? `url(${service?.icon?.publicURL})`
                          : "",
                      }}
                    ></div>
                  </div> :
                  <></>
                  }
                </div>
                <div className="title">
                  <h2>{service?.title2}</h2>
                </div>
                <div className="description">{service?.description}</div>
                {/* <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "1rem",
                  }}
                >
                  <button className="mach9">
                    <div>
                      Read more
                      <ArrowIcon />
                    </div>
                  </button>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
